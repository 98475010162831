import JshModule from '@core/scripts/helper/jsh-module';
import {openPrivacyCenter} from "./consent-functions.js";

const {moduleScope} = JshModule('consent/privacy-center-footer-link');

let privacyLayerFooterLink = moduleScope.querySelector('[jsh-modal-layer-link]');
if (privacyLayerFooterLink) {
    privacyLayerFooterLink.addEventListener('click', () => {
        openPrivacyCenter();
    });
}
